import axios from "../utils/axios";
// import { warning as API_WARNING } from "../utils/axios";
// import { dictionary as API_DICTIONARY } from "../utils/axios";
import apiPath from "../utils/apiPath";
const API_DICTIONARY = apiPath.dictionary;
const API_WARNING = apiPath.warning;
const API_PSM = apiPath.psm;

export default {
  /**
   * 告警列表
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  getAlarmList(params) {
    return axios.get(API_WARNING + "/app/page/alarm/records", { params });
  },
  /**
   * 告警详细
   * @param id 告警id
   * @returns {Promise<AxiosResponse<any>>}
   */
  getAlarmDetail(id) {
    return axios.get(API_WARNING + `/info/alarm/records/${id}`);
  },
  // /**
  //    * 可燃有毒报警列表
  //    * @param params
  //    * @returns {Promise<AxiosResponse<any>>}
  //    */
  // getPoisonAlarmList(params) {
  //   return axios.get(API_WARNING + "/page/alarm/new-records", { params });
  // },
  // /**
  //  * 可燃有毒报警总数
  //  * @param params
  //  * @returns {Promise<AxiosResponse<T>>}
  //  */
  // getAlarmRecordNewCount(params) {
  //   return axios.get(API_WARNING + "/alarm/record/new-count", { params });
  // },

  /**
   * 获取报警处置原因
   * @param params
   * @returns {Promise<AxiosResponse<T>>}
   */
  getAlarmGuideCause(params) {
    return axios.get(API_WARNING + `/list/indicator/alarm-guide-cause`, {
      params
    });
  },
  /**
   * 获取报警处置动作
   * @param params
   * @returns {Promise<AxiosResponse<T>>}
   */
  getAlarmGuideReact(params) {
    return axios.get(API_WARNING + `/list/indicator/alarm-guide-react`, {
      params
    });
  },

  /**
   * 告警日志
   * @param id 告警id
   * @returns {Promise<AxiosResponse<any>>}
   */
  getAlarmLog(params) {
    return axios.get(`${API_WARNING}/list/alarm/log`, { params });
  },
  /**
   * 获取告警状态
   * @returns {Promise<AxiosResponse<any>>}
   */
  getAlarmHandleStatus() {
    return axios.get(API_WARNING + "/list/alarm/handle_status");
  },
  /**
   * 获取检测项
   * @returns {Promise<AxiosResponse<any>>}
   */
  getIndicator() {
    return axios.get(API_DICTIONARY + "/select/indicator/type/dict");
  },
  /**
   * 获取告警级别
   * @returns {Promise<AxiosResponse<any>>}
   */
  getAlarmLevel() {
    return axios.get(API_WARNING + "/list/alarm/level");
  },
  /**
   * 提交报警反馈
   * @param data 反馈描述
   * @returns {Promise<AxiosResponse<any>>}
   */
  alarmFeedback(data) {
    return axios.post(API_WARNING + "/add/alarm/feedback", data);
  },
  // 报警点位模糊查询
  alarmSearchByName(params) {
    return axios.get(API_WARNING + "/page/alarm/records/fuzzy", { params });
  },
  /**
   * 告警总数
   * @param params
   * @returns {Promise<AxiosResponse<T>>}
   */
  getAlarmRecordCount(params) {
    return axios.get(API_WARNING + "/app/count/alarm/records", { params });
  },
  /**
   * 处置状态下拉选择数据
   * @param params
   * @returns {Promise<AxiosResponse<T>>}
   */
  getAlarmDisposalSltData() {
    return axios.get(API_WARNING + "/list/alarm/check_status");
  },
  /**
   * 确认报警
   * @param id
   * @returns {Promise<AxiosResponse<any>>}
   */
  confirmAlarm(id) {
    return axios.post(`${API_WARNING}/alarm/confirm/${id}`);
  },
  /**
   * 忽略报警
   * @param id
   * @returns {Promise<AxiosResponse<any>>}
   */
  ignoreAlarm(id) {
    return axios.post(`${API_WARNING}/alarm/ignore/${id}`);
  },
  /**
   * 报警处置 提交
   * @param id
   * @returns {Promise<AxiosResponse<any>>}
   */
  disposeAlarm(params) {
    return axios.post(`${API_WARNING}/alarm/dispose`, params);
  },
  /**
   * 报警处置 详情
   * @param id
   * @returns {Promise<AxiosResponse<any>>}
   */
  getDisposeAlarm(id) {
    return axios.get(`${API_WARNING}/info/alarm/dispose/${id}`);
  },
  /**
   * 报警核验 确认
   * @param id
   * @returns {Promise<AxiosResponse<any>>}
   */
  alarmCheck(id) {
    return axios.post(`${API_WARNING}/alarm/check/${id}`);
  },
  /**
   * 报警核验 驳回
   * @param id
   * @returns {Promise<AxiosResponse<any>>}
   */
  alarmReject(id) {
    return axios.post(`${API_WARNING}/alarm/reject/${id}`);
  }
};

/**
 * 查询人员报警类型下拉
 * @returns {*}
 */
export const getSelectLocationAlarmType = () => {
  return axios.get(`${API_PSM}/select/location/alarm-type`);
};

/**
 * 查询类型下拉
 * @returns {*}
 */
export const getSelectLocationPersonType = () => {
  return axios.get(`${API_PSM}/select/location/person-type`);
};

/**
 * 查询报警状态下拉
 * @returns {*}
 */
export const getSelectLocationAlarmStatus = () => {
  return axios.get(`${API_PSM}/select/location/alarm-status`);
};

/**
 * 查询处置状态下拉
 * @returns {*}
 */
export const getSelectLocationDealStatus = () => {
  return axios.get(`${API_PSM}/select/location/deal-status`);
};

/**
 * 分页查询报警记录
 * @param params
 * @returns {*}
 */
export const getPageLocationAlarm = params => {
  return axios.get(`${API_PSM}/page/alarm-record`, { params });
};

/**
 * 查询报警记录详情
 * @param id
 * @returns {*}
 */
export const getInfoLocationAlarm = id => {
  return axios.get(`${API_PSM}/info/alarm-record/${id}`);
};

/**
 * 添加处置信息
 * @param data
 * @returns {*}
 */
export const postLocationAlarmDeal = data => {
  return axios.post(`${API_PSM}/add/alarm-record-deal`, data);
};
