<template>
  <div>
    <van-nav-bar fixed title="处置日志" left-arrow @click-left="onClickLeft" />
    <div class="content">
      <van-steps
        v-if="logList.length"
        direction="vertical"
        :active="active"
        active-color="#1676FF"
      >
        <van-step v-for="(item, index) of logList" :key="index">
          <template #inactive-icon>
            <i
              :class="item.current === 1 ? 'active-icon' : 'inactive-icon'"
            ></i>
          </template>
          <template #active-icon>
            <i class="active-icon"></i>
          </template>

          <div class="process-item">
            <p class="operation-name">{{ item.operationName }}</p>
            <p class="operation-info">
              <van-icon class-prefix="iconfont" name="qiye" class="info-icon" />
              <span class="info-text">{{ item.cuserName }}</span>
              <van-icon
                class-prefix="iconfont"
                name="ico-timepoint"
                class="info-icon"
              />
              <span v-if="item.ctime" class="info-text">{{ item.ctime }}</span>
            </p>
          </div>
        </van-step>
      </van-steps>
      <div v-else class="empty-box">
        <img src="../../assets/images/empty-data.png" alt="" />
        <p>暂无日志</p>
      </div>
    </div>
  </div>
</template>

<script>
import alarm from "@/api/alarm";

export default {
  name: "AlarmDisposalLog",
  components: {},
  data() {
    return {
      alarmId: this.$route.params.id,
      logList: [],
      active: -1
    };
  },
  computed: {},
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getAlarmLogById();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    getAlarmLogById() {
      const params = {
        alarmRecordId: this.alarmId
      };
      alarm.getAlarmLog(params).then(data => {
        this.logList = data;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  overflow: auto;
  padding: 77px 25px 0;
  .inactive-icon {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #c3c6d0;
    border-radius: 50%;
  }
  .active-icon {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #ffffff;
    border: 2px solid $--color-primary;
    border-radius: 50%;
  }
  .process-item {
    padding: 0 0 0 10px;
    .operation-name {
      font-size: 14px;
      font-weight: 500;
      color: #2e2e4d;
      line-height: 20px;
      margin: 0 0 8px;
    }
    .operation-info {
      font-size: 12px;
      font-weight: 400;
      color: #8c8f97;
      line-height: 18px;
      .info-text {
        margin: 0 24px 0 0;
      }
      .info-icon {
        font-size: 16px;
        margin: 0 4px 0 0;
      }
    }
  }
  .empty-box {
    padding: 139px 0 0;
    text-align: center;
    p {
      font-size: 14px;
      font-weight: 400;
      color: #8c8f97;
      line-height: 20px;
    }
  }
}
</style>
